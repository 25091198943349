import React from 'react';

import Layout from '../components/Layout';
import { graphql } from "gatsby";

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Pagination from '../components/Pagination';
import Fade from 'react-reveal/Fade';

import PostTile from "../components/PostTile";
import Seo from '../components/Seo';


const BlogListTemplate = ({ data, ...props }) => {
  if (!data.allPrismicPost.edges) {
    return <></>;
  }
    return (
      <Layout>
        <Header title="Artigos" active="artigos" />
        <Seo />
        <div className="site-container blog-post">
          <div className="container">
            <div className="row">
              {data.allPrismicPost &&
                data.allPrismicPost.edges.map(({ node }, idx) => {
                  return (
                    <Fade key={`blog-list ${idx}`}>
                      <div
                        className={`${
                          idx === 0 && props.pageContext.currentPage < 2
                            ? ''
                            : 'col-md-6'
                        } col-sm-12`}
                      >
                        <PostTile post={node.data} />
                      </div>
                    </Fade>
                  );
                })}
            </div>
          </div>
        </div>
        <Pagination
          currentPage={props.pageContext.currentPage}
          totalPages={props.pageContext.numPages}
          uri="/blog"
        />
        <SocialLinks />
        <Footer />
      </Layout>
    );
};

export default BlogListTemplate;

export const blogQueryList = graphql`
  query BlogQueryList($skip: Int!, $limit: Int!) {
  allPrismicPost(
    filter: {data: {published: {eq: true}}}
    sort: {data: {date: DESC}}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        data {
          slug
          title {
            text
          }
          published
          category
          date
          author {
            document {
              ... on PrismicAuthor {
                type
                data {
                  name
                  listed
                  customer
                  internallink
                }
              }
            }
          }
          keywords {
            keyword
          }
          description {
            html
            text
          }
          content {
            html
            text
          }
          cover {
            url
            gatsbyImageData
          }
        }
      }
    }
  }
}
`;
