import React from 'react';
import Fade from 'react-reveal/Fade';

import moment from 'moment';

import { GatsbyImage } from "gatsby-plugin-image"
import {ReadingTime} from "../utils";
import { Link, navigate } from 'gatsby';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import 'moment/locale/pt';

moment.locale('pt');


library.add(...[faClock]);
const PostTile = ({ isFirst, post, ...props }) => {
  let minuts = Math.round(ReadingTime(post.content.text));
  
  return (
    <Fade bottom>
      <article
        className="blog-card"
        onClick={(e) =>
          e.target.tagName.toLowerCase() !== 'a' &&
          navigate('/artigos/' + post.slug)
        }
      >
        <div className={`meta ${isFirst ? '' : 'horizontal'}`}>
          <GatsbyImage
            Tag="span"
            image={post.cover.gatsbyImageData}
            className={'photo'}
            objectFit="cover"
          />
          <div className="details">
            <Link to={`/artigos/${post.slug}`}>
              <p className="card-text">{post.description.text}</p>
            </Link>
            <div className="category">
              {post.keywords &&
                post.keywords.map(({ keyword }, idx) => (
                  <Link
                    key={idx}
                    to={`/keywords/${keyword
                      .replace(/ /g, '-')
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')}/`}
                  >
                    {keyword}
                  </Link>
                ))}
            </div>
          </div>
        </div>

        <div className={`description ${isFirst ? '' : 'horizontal'}`}>
          <Link to={`/artigos/${post.slug}`}>
            <span className="read-time">
              <FontAwesomeIcon icon={'clock'} />
              {minuts > 1 ? `${minuts} minutos` : '1 minuto'}
            </span>
          </Link>
          <Link to={`/artigos/${post.slug}`}>
            <h1 className="card-title">{post.title.text}</h1>
          </Link>

          {post.author && (
            <p className="author">
              {moment(post.date).format('LL')} {"//"} por:
              {post.author.document &&
              post.author.document.data &&
              post.author.document.data.listed ? (
                <Link
                  to={`/${post.author.document.data.internallink
                    .replace(/ /g, '-')
                    .toLowerCase()}/`}
                >
                  {post.author.document.data.name}
                </Link>
              ) : (
                (post.author.document && post.author.document.data?post.author.document.data.name:"")
              )}
            </p>
          )}
        </div>
      </article>
    </Fade>
  );
};

export default PostTile;
