import React from "react";


function Pagination({ currentPage, totalPages, uri, totalButtons = 10 }) {
    let pages = []
    for (let i = 1; i <= totalButtons; i++ ){
        if (i <= totalPages){
            pages.push(Math.max(0, currentPage - totalButtons / 2) + i);
        }
    }
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          {currentPage !== 1 && totalPages !== 1 && (
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a
                className="page-link"
                tabIndex="-1"
                href={`${uri}/${currentPage === 2 ? '' : currentPage - 1}`}
              >
                Anterior
              </a>
            </li>
          )}
          {pages.map((p, idx) => (
            <li
              className={`page-item ${currentPage === p ? 'active' : ''}`}
              key={`pagination-${idx}`}
            >
              <a className="page-link" href={`${uri}/${p === 1 ? '' : p}`}>
                {p}
              </a>
            </li>
          ))}
          {totalPages !== 1 && totalPages !== currentPage && (
            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <a className="page-link" href={`${uri}/${currentPage + 1}/`}>
                Próxima
              </a>
            </li>
          )}
        </ul>
      </nav>
    );

}



export default Pagination;

